body {
    font: {
        family: $font-family-base;
        size: $font-size-base;
        weight: $font-weight-base;
    }

    line-height: $line-height-base;
    color: $dark-gray;
    word-break: break-word;

    background: {
        color: $body-bg;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font: {
            weight: $font-weight-medium;
        }
    }

    h1 {
        font-size: $h1-font-size;
    }

    h2 {
        font-size: $h2-font-size;
    }

    h3 {
        font-size: $h3-font-size;
    }

    h4 {
        font-size: $h4-font-size;
    }

    h5 {
        font-size: $h5-font-size;
    }

    h6 {
        font-size: $h6-font-size;
    }

    .resources-container {
        padding: 48px 0 0 0px;

        .resources-context {
            margin: 24px;
        }
    }

    .show-img {
        width: 48px;
        height: 48px;
        border-radius: 2px;
    }

    .btn-big {
        height: 40px;
        box-shadow: 0px 3px 6px #00000029;
    }

    .btn-ghost {
        color: $deep-purple !important;
        border-color: $deep-purple !important;
    }

    .btn-ghost:active:hover,
    .btn-ghost:hover {
        color: $deep-purple-hover !important;
        border-color: $deep-purple-hover !important;
    }

    .btn-actions {
        height: 24px;
        display: flex;
        align-items: center;
        border-radius: 2px;
        border-color: #cfccd8;
        color: $deep-purple;
    }

    .btn-cancel {
        width: 108px;
        background-color: $white;
        border: 1px solid $light-gray;
        @include border-radius(4px);
        font-size: $h5-font-size;
    }

    .btn-save {
        width: 108px;
        background-color: $primary;
        border: 1px solid $primary;
        @include border-radius(4px);
        font-size: $h5-font-size;
    }

    .btn-detail {
        min-width: auto;
        background-color: $primary;
        border: 1px solid $primary;
        @include border-radius(15px);
    }

    .btn-edit {
        min-width: 108px;
        background-color: $white;
        border: 1px solid $light-gray;
        @include border-radius(4px);
        font-size: $h5-font-size;
    }

    .ant-form-item-label > label {
        height: auto !important;
    }

    @include xs {
        .resources-container {
            padding: 48px 0 0 48px;
        }
    }
}
