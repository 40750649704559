#organizations-form {
    .spin-general {
        position: fixed;
    }

    .form-container {
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-bottom: 24px;

        .card {
            background-color: $white;
            border-radius: 2px;

            .header {
                padding: 16px 24px;
                border-bottom: 1px solid $light-gray;
                font-size: $h4-font-size;
            }

            .body {
                padding: 24px 48px;
            }
        }

        .general-info {
            .body {
                display: flex;
                flex-wrap: wrap;
                gap: 0 24px;

                .ant-form-item {
                    width: calc(100% * (1/3) - 24px);
                }

                .ant-picker-range {
                    width: 100%;
                }

                .ant-form-item-tooltip {
                    position: absolute;
                    right: 0;
                }

                .form-tooltip {
                    .ant-form-item-label>label {
                        width: 100%;
                    }
                }
            }
        }

        .buttons-footer {
            margin-bottom: 24px;
            padding: 24px;
        }

        .upload-list-inline {
            .ant-upload-list-item {

                margin-top: 0;
            }
        }
    }


}


// #image-cropper {
    //     @include flexbox();
    //     @include align-items(center);
    //     width: -webkit-fill-available;
    //     gap: 20px;

    //     .uploader-preview-img {

    //         img {
    //             width: 48px;
    //             height: 48px;
    //             border-radius: 2px;
    //         }
    //     }

    //     .delete-button {
    //         span:hover {
    //             color: $danger;
    //             cursor: pointer;
    //         }
    //     }

    //     .button-uploader {
    //         padding: 7px 17px 6px 18px;
    //         @include border-radius(3px);
    //         @include border(solid, 1px, $light-grey);
    //         background-color: $white;

    //         span {
    //             margin-left: 10px;
    //         }

    //         &:hover {
    //             @include border(solid, 1px, $info-hover);
    //             cursor: pointer;
    //         }
    //     }
    // }

    // #modal-cropper-container {
    //     min-height: 400px;
    //     position: relative;
    // }

    // #cropper-slider {
    //     position: absolute;
    //     bottom: 0;

    //     .rs-tooltip {
    //         display: none !important;
    //     }
    // }

    // .reactEasyCrop_Container {
    //     margin: 10px;
    // }


@include sm-max {
    #organizations-form {
        .form-container {
            .general-info {
                .body {
                    .ant-form-item {
                        width: calc(100% * (1/2) - 24px);
                    }
                }
            }
        }
    }
}

@include xs-max {
    #organizations-form {
        .form-container {
            .general-info {
                .body {
                    .ant-form-item {
                        width: 100%;
                    }
                }
            }
        }
    }
}