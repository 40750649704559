#brands-form {
    .spin-general {
        position: fixed;
    }

    .form-container {
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-bottom: 24px;

        .card {
            background-color: $white;
            border-radius: 2px;

            .header {
                padding: 16px 24px;
                border-bottom: 1px solid $light-gray;
                font-size: $h4-font-size;
            }

            .body {
                padding: 24px 48px;
            }
        }

        .general-info {
            .body {
                display: flex;
                flex-wrap: wrap;
                gap: 0 24px;

                .ant-form-item {
                    width: calc(100% * (1/3) - 24px);
                }

                .ant-picker-range {
                    width: 100%;
                }

                .ant-form-item-tooltip {
                    position: absolute;
                    right: 0;
                }

                .form-tooltip {
                    .ant-form-item-label>label {
                        width: 100%;
                    }
                }
            }
        }

        .buttons-footer {
            margin-bottom: 24px;
            padding: 24px;
        }

        .upload-list-inline {
            .ant-upload-list-item {

                margin-top: 0;
            }
        }
    }
}




@include sm-max {
    #brands-form {
        .form-container {
            .general-info {
                .body {
                    .ant-form-item {
                        width: calc(100% * (1/2) - 24px);
                    }
                }
            }
        }
    }
}

@include xs-max {
    #brands-form {
        .form-container {
            .general-info {
                .body {
                    .ant-form-item {
                        width: 100%;
                    }
                }
            }
        }
    }
}