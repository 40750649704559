#login {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100vh;
    overflow: hidden;
    background-color: $background-login;

    .left-container {
        overflow: hidden;
        @include flexbox();
        @include justify-content(center);
        @include align-items(center);

        img {
            height: 100vh;
            width: 100%;
        }

        .smooth-image {
            transition: opacity 1s;
        }

        .image-visible {
            opacity: 1;
        }

        .image-hidden {
            opacity: 0;
        }
    }

    .right-container {
        @include flexbox();
        @include justify-content(center);
        @include align-items(center);
        height: 100vh;
        padding: 20px;
        width: 100%;

        .context {
            width: 100%;
            max-width: 320px;

            .logo {
                background-image: url('../../img/logo-switch.svg');
                background-repeat: no-repeat;
                background-size: auto;
                width: 100%;
                height: 30px;
                margin-bottom: 20px;
            }

            .title {
                font-size: $h2-font-size;
                font-weight: $font-weight-semi-bold;
                margin-bottom: 10px;
            }

            .subtitle {
                font-size: $h4-font-size;
                margin-bottom: 20px;
            }

            .ant-form-item-label
                > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
                display: none;
            }

            .buttons-container {
                @include flexbox();
                @include justify-content(space-between);
                @include align-items(center);

                .buttons-link {
                    @include flexbox();
                    flex-direction: column;
                    font-size: $h4-font-size;
                }

                .btn-login {
                    font-size: $h4-font-size;
                    height: auto;
                    color: $deep-purple;
                    background-color: $primary;
                    border-color: $primary;
                }

                .btn-login:hover {
                    color: $deep-purple;
                    background-color: $primary-color-hover;
                    border-color: $primary-color-hover;
                }
            }

            .errors {
                background-color: $danger-background;
                border: 1px solid;
                border-color: $danger-border;
                padding: 24px;
                @include flexbox();
                gap: 8px;
                margin-bottom: 20px;

                .icon {
                    color: $danger;
                }

                .text {
                    font-size: $h6-font-size;
                }
            }

            .success {
                background-color: $success-background;
                border: 1px solid;
                border-color: $success-border;
                padding: 24px;
                @include flexbox();
                gap: 8px;
                margin-bottom: 20px;

                .icon {
                    color: $success;
                }

                .text {
                    font-size: $h6-font-size;
                }
            }
        }
    }
}

@include xs-max {
    #login {
        .left-container {
            display: none;
        }
    }
}
